:root {
    --main-red: #cc0001;
    --main-dark: #06264d;
}
body{
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section{
    width: 100%;
}
ul{
  list-style: none;
  display: flex;
}
a{
  text-decoration: none;
}
img{
  width: 100%;
}
.post-date{
  background-color: var(--main-dark);
  color: white;
  height: 30px;
  font-size: 14px;
  width: 115px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  left: 10px;
  z-index: 5;
  bottom: 10px;
}

.row{
  display: flex;
}
.col{
    width: 50%;
}

.my-container{
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
}
.primary-button{
  border: 2px solid var(--main-dark);
  outline: none;
  background-color: white;
  color: var(--main-dark);
  font-size: 16px;
  transition: .4s ease-in;
  border-radius: 3px;
}
.primary-button:hover{
  background-color: var(--main-dark);
  color: white;
}
.craouser-button{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.craouser-next-button{
  top: -75px;
  right: 65px;
}
.carousel-prev-button{
  top: -75px;
  right: 10px;
}
.grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.grid-container .craousel-card{
  padding: 0;
}
.sub-category-nav{
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  display: flex;
}
.sub-category-nav li{
  font-size: 16px;
  font-weight: 600;
  color: var(--main-dark);
  cursor: pointer;
  transition: .3s ease-in;
  margin: 0 8px;
 
}
.sub-category-nav li:hover,.sub-category-nav .active{
  color: var(--main-red);
}

/* typography */

.nav-link{
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: #1d0025;
}
.primary-heading{
  font-size: 28px;
  font-weight: 800;
  color: var(--main-red);
}
.secondary-heading{
  font-size: 17px !important;
  font-weight: 800;
  color: var(--main-dark);

}
.footer-link{
  font-size: 15px;
  font-weight: 400;
  color: #ffffff9d;
}
.widget-title{
  font-size: 20px;
  color: white;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;

}
.page-tilte{
  font-size: 50px;
  color: white;
}
.sub-menu svg{
  width: 14px;
  margin-left: 5px;
  transform: translateY(3px);
}
p{
  font-size: 16px;
  font-weight: 400;
  color: #555;
  line-height: 22px;
}
.accent-heading{
  font-size: 18px;
  color: var(--main-dark);
  margin-bottom: 5px;
}


/* Header */
#header-top{
   background-color: var(--main-red);
   padding: 15px 0;
   transition: .5s ease;
}
.nav-box{
  padding: 10px 0;
  transition: .3s ease;  
}
#header{
  box-shadow: 0 10px 15px #6e3dcf18;
  z-index: 99;
  background-color: white;
}
.nav-b-x-inner{
   justify-content: space-between;
   align-items: center;
}
.nav-item{
    margin: 0 20px;
    position: relative;
}
.navbar-nav > li:last-child{
  margin-right: 0 !important;
}
.nav-mobie-overlay,.toggle{
    display: none;
}
.rfm-marquee{
  height: 100% !important;
  align-items: flex-start !important;

}


.sub-menu{
    position: absolute;
    flex-direction: column;
    min-width: 200px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 15px #6e3dcf18;
    left: 0;
    border-radius: 10px;
    transition: .3s ease-in;
    display: none;
    padding: 20px 0px 20px 20px;
}
.sub-menu-2{
  left: 100%;
  top: 0;
}
.sub-menu li{
  width: 100%;

}
.sub-menu li:hover > .sub-menu-2{
  display: block;
}
.sub-menu-2s li:hover a{
  color: var(--main-red);
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
}
.marquee-content {
  display: block;
  position: absolute;
  width: 100%;
  animation: scrollUp 10s linear infinite;
}
.category-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  row-gap: 20px;
  padding: 0 10px;
}
.marquee-content:hover {
  animation-play-state: paused;
}
.sub-menu li{
 margin-left: 0 !important;
  margin-bottom: 7px;
  padding: 0 !important;
}
.sub-menu li:last-child{
  margin-bottom: 0;
}
.nav-item:hover > .sub-menu{
  display: block;
}
.nav-item .active p,.nav-item:hover > a > p{
  color: var(--main-red);
}
.nav-item p{
  white-space: nowrap;
}


/* hero */
.hero-row{
    display: flex;
}
.hero-righ{
    width: 30%;
}
.hero-left{
    width: 70%;
}
.hero-right-header{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-red);
}
.hero-right-body{
    width: 100%;
    height: calc(100% - 74px);
    background-color: var(--main-dark);
}

/* craosel-section */
.post-cre-header{
  padding: 0 0px 10px 0px;
  border-bottom: 2px solid #dadfe4;
  margin: 0 10px 30px 10px;
  position: relative;
}
.post-cra-container{
  position: relative;
  margin-top: 50px;
}
.post-cra-container:nth-child(1){
  margin-top: 0;
}

/* craousel-card */
.craousel-card{
  padding: 0 10px;

 
}
.cat-cart{
  border-radius: 15px;
  
}
.craouser-card-box{
  background-color: #dadfe4;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}
.cat-box{
  background-color: #fff;
  box-shadow: 0 10px 15px #6e3dcf18;
  border-radius: 15px;
  overflow: hidden;
}
.craouser-card-box img{
  height: 150px;
  object-fit: cover;
}
.craouser-card-heading{
  padding: 20px;
}

.breadcrumb-container{
  width: 100%;
  height: 180px;
  background-color: var(--main-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}


/* contact us */
.contact-heading{
  padding-bottom: 60px;
  text-align: center;
}
.contact-heading h1{
  position: relative;
}
.contact-heading h1::after{
  content: '';
  position: absolute;
  width: 40px;
  height: 7px;
  border-radius: 4px;
  background-color: var(--main-dark);
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
.contact-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.contact-left,.contact-right{
  width: 100%;
}
.contact-row-2,.contact-row-1{
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
}
.contact-row-2{
  grid-template-columns: 1fr 1fr;
}
.contact-row-1{
  grid-template-columns: 1fr;
}
input,textarea{
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border: none;
  outline: none;
  background-color: #06264d23;
  border-radius: 4px;
  font-size: 16px;
  color: var(--main-dark);
  border: 1px solid transparent;
}
input:hover,textarea:hover,input:focus,textarea:focus{
  border: 1px solid var(--main-dark);
}
textarea{
  height: 150px;
  padding-top: 15px;
}
.submit-button{
  width: 100%;
  height: 50px;
  border-radius: 4px;
  font-weight: 500;
  background-color: var(--main-dark);
  color: white;
  cursor: pointer;
}
.contact-info-con{
  display: flex;
  align-items: center;
}
.contact-info-left{
  width: 50px;
  height: 50px;
  background-color: var(--main-dark);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 20px;
}
.contact-info-left svg{
  height: 20px;
  width: 20px;
}
.social-icon{
  width: 50px;
  height: 50px;
  background-color: var(--main-red);
  transition: .4s ease-in;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon:hover{
  background-color: var(--main-dark);
}
.social-icon a{
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon a svg{
  width: 20px;
  height: 20px;
}
.social-bos{
  margin-top: 15px;
  display: flex;
}

/* about us */
.about-heading{
  margin-bottom: 20px;
}
.about-indo-box{
  display: flex;
 
}
.about-left{
  position: relative;
}
.about-big{
  width: calc(100% - 200px);
  height: 500px;
  object-fit: cover;
  border-radius: 8px;
}
.about-sm{
  position: absolute;
  width: 250px;
  right: 50px;
  bottom: -70px;
  border-radius: 8px;
}
.about-info-left{
  width: 70px;
  height: 70px;
  min-width: 70px;
  background-color: var(--main-dark);
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.about-info-left svg{
  width: 30px;
  height: 30px;
}
.whyusgrid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.whyus-box{
  box-shadow: 0 10px 15px #6e3dcf18;
  padding: 30px;
  border-radius: 15px;
}
.whyus-icon{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whyus-icon-odd{
  background-color: #06264d36;
  color: var(--main-dark);
}
.whyus-icon-even{
  background-color: #cc000022;
  color: var(--main-red);
}

/* post */
.post-box{
  display: flex;
  width: 100%;
}
.post-sidebar{
  width: 220px;
}
.post-sidebar-right{
  padding: 60px 0 60px 30px;
}
.post-sidebar-left{
  padding: 60px 30px 60px 0;
}
.post-content{
  padding: 0 30px;
  border: 1px solid #e0e0e0;
}
.sidebar-link-m{
  color: var(--main-red);
  margin-bottom: 10px;
  font-weight: 600;
  
}
.hero-carsoul-btn{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 55;
}
.hero-crasou-next{
  left: 20px;
}
.hero-crasou-pre{
  right: 20px;
}

.sidebar-link-group,.related-post{
  margin-bottom: 10px;
  background-color: #06264d30;
  padding: 20px;
  border-radius: 8px;
}
.sidebar-link-c{
  color: #202020;
  font-size: 15px;
}
.sidebar-link-c:hover,.sidebar-link-m:hover{
  color: var(--main-red);
}
.post-content ul{
 flex-direction: column !important;
}
.post-content{
  flex: 1;
}
.sidebar-title{
  position: relative;
}
.sidebar-title::before{
  content: '';
  width: 40px;
  height: 7px;
  background-color: var(--main-red);
  display: flex;
  border-radius: 4px;
  position: absolute;
  left: 0;
  bottom: -10px;
}

/* 
footer */

#subscribe{
  background-color: var(--main-red);
  padding: 20px 0;
}

#footer{
  background-color: var(--main-dark);
  padding-top: 90px;
}
#footer .row{
  justify-content: space-between;
}
.footer-nav{
  flex-direction: column;
}
.footer-nav li{
  margin-bottom: 10px;
}
.footer-col{
  width: calc(25% - 60px);
}
.footer-top{
  padding-bottom: 50px;
}
.footer-bottom{
  padding: 10px 0;
  border-top: 2px solid #9f9f9f7e;
}
.footer-bottom li{
  margin: 0 10px;
}
.footer-bottom li:nth-child(1){
  margin-left: 0;
}
.footer-contact{
  display: flex;
}
.footer-contact span{
  margin-left: 10px;
}
.footer-logo{
  margin-bottom: 20px;
}
.copyright-list{
  width: 100%;
  justify-content: space-between;
}
.latest-link:hover{
  color: var(--main-red);
}
@media only screen and (max-width: 1041px) {
  body{
    overflow-x: hidden;
  }
  .grid-container{
    grid-template-columns: 1fr 1fr;
  }
  .page-tilte{
    text-align: center;
  }
  .post-sidebar{
    display: none;
  }
  .post-content{
    padding: 0;
    border: none;
  }
  .gjs-cell{
    height: auto !important;
  }
  .heading-primary{
    font-size: 30px;
    line-height: 35px;
  }
  .primary-heading{
    font-size: 25px;
  }

  #page{
    padding-top: 121px !important;
  }
  .sub-menu{
    display: none !important;
  }
  .nav-link svg{
    display: none;
  }
    .toggle{
      display: block;
    }
  
  
    .hero-righ{
      margin-top: -7px;
      height: 353px;
    }
    .category-grid{
      grid-template-columns: 1fr 1fr;
    }
    .sub-box{
      flex-direction: column;
    }
    .sub-box h1{
      text-align: center;
      margin-bottom: 10px;
    }
    .sub-box input{
      min-width: 200px !important;
    }
    .header-btn{
      display: none;
    }
    .nav-desktop{
      display: none !important;
    }
    .nav-mobie-overlay{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #000815c6;
      display: block;
      transition: .4s ease;
      z-index: 555555;
    }
    .collapsed{
      left: -100%;
    }
    .nav-mobile-sidebar{
      width: 70%;
      max-width: 400px;
      height: 100vh;
      background-color: white;
      z-index: 55555;
      transition-delay: .2s;
      transition: .4s ease;
    
    }
    .collapsed .nav-mobile-sidebar{
      left: -100%;
    }
    .mobile-nav-header{
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f9f9f9;
  
    }
    .mobile-nav-body{
      padding: 20px;
    }
    .mobile-nav-body ul{
      flex-direction: column;
    }
    .mobile-nav-body ul li{
      margin: 0;
      padding: 8px 0;
    }
  
    /* global */
    .row{
      flex-direction: column;
    }
    .col{
      width: 100%;
    }
    nav.row{
      flex-direction: row;
    }
  
  
    /* typography mobile */
    .nav-link{

      font-size: 17px;
      font-weight: 500;
      color: #1d0025;
    }
    .hero-heading{
      font-size: 45px;
      line-height: 50px;
    }
    .text{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    .sub-heading{
      font-size: 22px;
    }
    .heading-primary{
      font-size: 35px;
      line-height: 40px;
    }
    .accent-heading{
      font-size: 18px;
      color: var(--main-dark);
    }
    .col{
      width: 100%;
    }
  /* 
    hero mobile */
  
    #hero-section{
      height: 85vh;
    }
    .hero-content{
      width: 100%;
    }
    .hero-content p{
      margin: 25px 0 30px 0 ;
    }
    .cat-card-box{
      padding: 50px 10px;
    }
    .hero-h-box{
      min-height: 280px !important;
      height: 280px;
    }
   
  
    /* about mobile */
    .abt-col-right{
      margin-top: 30px;
    }
    .abt-col-left{
      padding: 0;
    }
    .how-card{
      width: 100% !important;
      margin-bottom: 200px;
    }
    .how-card:nth-child(3){
      margin-bottom: 0;
    }
    .footer-col{
      width: 100%;
      padding-left: 0 !important;
      padding-bottom: 40px;
    }
    .city-col{
      width: 100%;
      margin: 20px 0;
    }
    .city-title .col{
      display: flex;
      justify-content: center !important;
    }
    .city-title .col:nth-child(2){
      margin-top: 20px;
    }
    .addon-hero-img{
      width: 100%;
      object-fit: cover;
      margin-top: 30px;
    }
  
    #slot .col,#review .col{
      width: 100%;
    }
    .addon-card{
      width: 100%;
      min-width: calc(25% - 40px);
    }
    .btn-row .btn-gr-border{
      margin: 15px 0;
    }
    .contact-grid{
      grid-template-columns: 1fr;
    }
    .whyusgrid{
      grid-template-columns: 1fr 1fr;
    }
    .about-sm{
      display: none;
    }
    .about-big{
      width: 100%;
    }
    .gallery-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
  }
  
   
}


@media only screen and (max-width: 768px) {
  body{
    overflow-x: hidden;
  }
  .whyusgrid{
    grid-template-columns: 1fr;
  }
  .grid-container{
    grid-template-columns: 1fr;
  }
  .page-tilte{
    text-align: center;
  }
  .post-sidebar{
    display: none;
  }
  .post-content{
    padding: 0;
    border: none;
  }
  .gjs-cell{
    height: auto !important;
  }
  .heading-primary{
    font-size: 30px;
    line-height: 35px;
  }
  .primary-heading{
    font-size: 25px;
  }
  .copyright-list{
    flex-direction: column;
  }
  .copyright-list li{
    text-align: center;
  }
  #header-top .my-container{
    flex-direction: column;
    align-items: center;
  }
  #header-top .my-container a:nth-child(1){
    margin-bottom: 7px;
  }
  #page{
    padding-top: 153px !important;
  }
  .sub-menu{
    display: none !important;
  }
  .nav-link svg{
    display: none;
  }
    .toggle{
      display: block;
    }
    .hero-row{
      flex-direction: column;
    }
    .hero-left,.hero-righ{
      width: 100%;
    }
    .hero-righ{
      margin-top: -7px;
      height: 300px;
    }
    .category-grid{
      grid-template-columns: 1fr;
    }
    .sub-box{
      flex-direction: column;
    }
    .sub-box h1{
      text-align: center;
      margin-bottom: 10px;
    }
    .sub-box input{
      min-width: 200px !important;
    }
    .header-btn{
      display: none;
    }
    .nav-desktop{
      display: none !important;
    }
    .nav-mobie-overlay{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #000815c6;
      display: block;
      transition: .4s ease;
      z-index: 555555;
    }
    .collapsed{
      left: -100%;
    }
    .nav-mobile-sidebar{
      width: 70%;
      max-width: 400px;
      height: 100vh;
      background-color: white;
      z-index: 55555;
      transition-delay: .2s;
      transition: .4s ease;
    
    }
    .collapsed .nav-mobile-sidebar{
      left: -100%;
    }
    .mobile-nav-header{
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f9f9f9;
  
    }
    .mobile-nav-body{
      padding: 20px;
    }
    .mobile-nav-body ul{
      flex-direction: column;
    }
    .mobile-nav-body ul li{
      margin: 0;
      padding: 8px 0;
    }
  
    /* global */
    .row{
      flex-direction: column;
    }
    .col{
      width: 100%;
    }
    nav.row{
      flex-direction: row;
    }
  
  
    /* typography mobile */
    .nav-link{

      font-size: 17px;
      font-weight: 500;
      color: #1d0025;
    }
    .hero-heading{
      font-size: 45px;
      line-height: 50px;
    }
    .text{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
    .sub-heading{
      font-size: 22px;
    }
    .heading-primary{
      font-size: 35px;
      line-height: 40px;
    }
    .accent-heading{
      font-size: 18px;
      color: var(--main-dark);
    }
    .col{
      width: 100%;
    }
  /* 
    hero mobile */
  
    #hero-section{
      height: 85vh;
    }
    .hero-content{
      width: 100%;
    }
    .hero-content p{
      margin: 25px 0 30px 0 ;
    }
    .cat-card-box{
      padding: 50px 10px;
    }
    .hero-h-box{
      min-height: 280px !important;
      height: 280px;
    }
   
  
    /* about mobile */
    .abt-col-right{
      margin-top: 30px;
    }
    .abt-col-left{
      padding: 0;
    }
    .how-card{
      width: 100% !important;
      margin-bottom: 200px;
    }
    .how-card:nth-child(3){
      margin-bottom: 0;
    }
    .footer-col{
      width: 100%;
      padding-left: 0 !important;
      padding-bottom: 40px;
    }
    .city-col{
      width: 100%;
      margin: 20px 0;
    }
    .city-title .col{
      display: flex;
      justify-content: center !important;
    }
    .city-title .col:nth-child(2){
      margin-top: 20px;
    }
    .addon-hero-img{
      width: 100%;
      object-fit: cover;
      margin-top: 30px;
    }
  
    #slot .col,#review .col{
      width: 100%;
    }
    .addon-card{
      width: 100%;
      min-width: calc(25% - 40px);
    }
    .btn-row .btn-gr-border{
      margin: 15px 0;
    }
    .gallery-grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
  }
  
   
}
